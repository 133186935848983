import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('#menu-main-menu .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Semi Sticky Nav ======================  */
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	document.addEventListener('scroll', function (event) {
		var currentScrollPos = window.pageYOffset;

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled");
		}
	}, true);

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {});

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('#gform_fields_2 .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== Accordion ======================  */
	//$('.accordion .accordion-row:first-of-type .accordion-row-intro').addClass('active');
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').slideDown();
	//$('.accordion .accordion-row:first-of-type .accordion-row-content').addClass('active');

	$('.question-filters .filter').on('click', function () {
		var cat_related = $(this).attr('cat-related')
		$('.question-filters .filter').removeClass('active')
		$(this).addClass('active');

		$('.questions .question').removeClass('active')
		$('.questions .answer').slideUp();
		$('.questions .answer').removeClass('active');

		$('.questions .categories .category').removeClass('active');
		$('.questions .categories .category[cat-related='+ cat_related +']').addClass('active');
	});

	$('.questions .question').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.answer').slideUp();
			$(this).next('.answer').removeClass('active');
		} else {
			$('.questions .question').removeClass('active');
			$('.answer').slideUp();
			$('.answer').removeClass('active');

			$(this).addClass('active');
			$(this).next('.answer').slideDown();
			$(this).next('.answer').addClass('active');
		}
    });

    $('.questions .row .close').on('click', function () {
    	var row = $(this).parents('.row');

    	$(row).find('.answer').slideUp();
    	$(row).find('.question').removeClass('active');
    });

/* ====================== Latest Posts Carousel ======================  */
	const latest_posts_carousel = new Swiper('.latest-posts-carousel .swiper', {
       	slidesPerView: 1.25,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 8,
       	navigation: {
			nextEl: ".latest-posts-carousel .swiper-button-next",
			prevEl: ".latest-posts-carousel .swiper-button-prev",
		},
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3.2,
				spaceBetween: 30,
			},

			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
		},
	});

/* ====================== Give Tip Carousel ======================  */
	const give_tip_strip_carousel = new Swiper('.give-a-tip-strip .carousel', {
       	slidesPerView: 1,
       	speed: 300,
       	loop: true,
       	autoHeight: true,
		pagination: {
			el: ".give-a-tip-strip .swiper-pagination",
			clickable: true
		},
	});

	give_tip_strip_carousel.on('slideChange', function () {
		var currentIndex = give_tip_strip_carousel.activeIndex;
		$('.give-a-tip-strip h4').removeClass('active');
		$('.give-a-tip-strip h4[index="'+ currentIndex+ '"').addClass('active');
		$(this).addClass('active');
	});

	$('.give-a-tip-strip h4').on('click', function() {
		$('.give-a-tip-strip h4').removeClass('active');
		$(this).addClass('active');
		var index = $(this).attr('index');
		give_tip_strip_carousel.slideTo(index);
	});

/* ====================== Recieve Tip Carousel ======================  */
	const recieve_tip_carousel = new Swiper('.recieve-a-tip-strip .carousel', {
       	slidesPerView: 1,
       	speed: 300,
       	loop: true,
       	autoHeight: true,
		pagination: {
			el: ".recieve-a-tip-strip .swiper-pagination",
			clickable: true
		},
	});

	recieve_tip_carousel.on('slideChange', function () {
		var currentIndex = recieve_tip_carousel.activeIndex;
		$('.recieve-a-tip-strip h4').removeClass('active');
		$('.recieve-a-tip-strip h4[index="'+ currentIndex+ '"').addClass('active');
		$(this).addClass('active');
	});

	$('.recieve-a-tip-strip h4').on('click', function() {
		$('.recieve-a-tip-strip h4').removeClass('active');
		$(this).addClass('active');
		var index = $(this).attr('index');
		recieve_tip_carousel.slideTo(index);
	});
/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
	     $('#gform_fields_3 .btn, #gform_fields_4 .btn').on('click', function() {
        	$(this).parents('.gform_wrapper').find('input[type=submit]').click();
        });
	});

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

/* ======================  Partners ======================  */
	if(windowWidth < 1024) {
		$('.partners .logos-container').marquee({
			duration: 10000,
			duplicated: true,
			gap: 50,
			startVisible: true,
			direction: 'left',
		});
	}

/* ======================  Marquee ======================  */
	var duration = 20000;

	if(windowWidth < 768) {
		duration = 10000;
	}
	$('.how-it-works-strip .marquee').each(function(index){
		var direction = 'right';
		if(index == 1) {
			direction = 'left'
		}

		if(index == 3) {
			direction = 'left'
		}

		$(this).marquee({
			duration: duration,
			duplicated: true,
			gap: 35,
			startVisible: true,
			direction: direction,
		});
	})


/* ====================== Smooth Scroll - Doesn't conflict with ajax smooth scroll ======================  */
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
	  // Prevent default anchor click behavior
	  event.preventDefault();

	  // Store hash
	  var hash = this.hash;

	  // Using jQuery's animate() method to add smooth page scroll
	  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	  $('html, body').animate({
	    scrollTop: $(hash).offset().top
	  }, 500, function(){

	    // Add hash (#) to URL when done scrolling (default click behavior)
	    window.location.hash = hash;
	  });
	} // End if
	});
});
